import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ComplexPersonCondition, ConditionalKeys, DEFAULT_KEYS, PersonCondition, PersonMatcher, SimplePersonCondition, StandardConditions } from '@weavix/models/src/person/person-matcher';
import { PersonSelect } from '@weavix/models/src/person/person-select';
import { Chip } from 'components/chip-list/chip-list.component';
import { DropdownItem } from 'components/dropdown/dropdown.model';
import { isEqual } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { CompanyService } from 'weavix-shared/services/company.service';
import { CraftService } from 'weavix-shared/services/craft.service';
import { FacilityService } from 'weavix-shared/services/facility.service';
import { PersonImportService } from 'weavix-shared/services/person-import.service';
import { PersonService } from 'weavix-shared/services/person.service';
import { ProfileService } from 'weavix-shared/services/profile.service';
import { TagService } from 'weavix-shared/services/tag.service';

export type SimpleConditionForm = FormGroup<{
    key: FormControl<ConditionalKeys>;
    negate: FormControl<boolean>;
    value: FormControl<string[]>;
    self: FormControl<boolean>;
}>;

export type ComplexConditionForm = FormGroup<{
    conditions: FormArray<FormGroup>;
    type: FormControl<string>;
    enabled?: FormControl<boolean>;
}>;

export type NegateOptions = Partial<Record<ConditionalKeys, Array<DropdownItem & { control?: FormGroup }>>>;

export interface MatcherOptions {
    includes?: ConditionalKeys[];
    legacy?: boolean;
    people?: PersonSelect[];
}

@Injectable({
    providedIn: 'root',
})
export class PersonMatcherService {
    allValues: Record<ConditionalKeys, Chip[]>;
    formValues: Record<ConditionalKeys, Chip[]>;
    key$ = new BehaviorSubject<DropdownItem[]>([]);
    negate$ = new BehaviorSubject<NegateOptions>({});

    allPeople: PersonSelect[] = [];
    self: PersonSelect;

    constructor(
        private personService: PersonService,
        private personImportService: PersonImportService,
        private companyService: CompanyService,
        private tagService: TagService,
        private craftService: CraftService,
        private facilityService: FacilityService,
        private profileService: ProfileService,
    ) { }

    invalidateCache() {
        this.allValues = null;
        this.formValues = null;
        this.allPeople = [];
        this.self = null;
    }

    async loadValues() {
        if (this.allValues) return;

        const [peopleResult, pendingPeople, requestedPeople, importedPeople] = await Promise.all([
            this.personService.getPeople(null),
            this.personService.getPendingPeople(null),
            this.personService.getRequestedPeople(null),
            this.personImportService.getImportedPeople(null),
        ]);
        const people = [...peopleResult, ...pendingPeople, ...requestedPeople, ...importedPeople];

        const [facilities, crafts, tags, companies] = await Promise.all([
            this.facilityService.getAll(null),
            this.craftService.getAll(null),
            this.tagService.getAll(null),
            this.companyService.getAllOnAccount(null),
        ]);

        this.allPeople = people;
        const person = await this.profileService.getUserProfile(null);
        this.self = people.find(v => v.id === person.id);

        this.allValues = {
            [ConditionalKeys.People]: people.map(person => ({ id: person.id, name: person.fullName })),
            [ConditionalKeys.PersonSites]: facilities.map(site => ({ id: site.id, name: site.name })),
            [ConditionalKeys.PersonCraft]: crafts.map(craft => ({ id: craft.id, name: craft.name })),
            [ConditionalKeys.PersonTags]: tags.map(tag => ({ id: tag.id, name: tag.name })),
            [ConditionalKeys.PersonCompany]: companies.map(company => ({ id: company.id, name: company.name })),
        };

        this.formValues = this.allValues;
    }

    filterPeople(people: PersonSelect[]) {
        const facilities = new Set<string>();
        const companies = new Set<string>();
        const tags = new Set<string>();
        const crafts = new Set<string>();
        people.forEach(person => {
            Object.keys(person.facilities ?? {}).forEach(facilityId => facilities.add(facilityId));
            person.crafts?.forEach(craft => crafts.add(craft));
            person.tags?.forEach(tag => tags.add(tag));
            companies.add(person.companyId);
        });

        this.formValues[ConditionalKeys.People] = people.map(person => ({ id: person.id, name: person.fullName }));
        this.formValues[ConditionalKeys.PersonSites] = this.allValues[ConditionalKeys.PersonSites].filter(v => facilities.has(v.id));
        this.formValues[ConditionalKeys.PersonCompany] = this.allValues[ConditionalKeys.PersonCompany].filter(v => companies.has(v.id));
        this.formValues[ConditionalKeys.PersonCraft] = this.allValues[ConditionalKeys.PersonCraft].filter(v => crafts.has(v.id));
        this.formValues[ConditionalKeys.PersonTags] = this.allValues[ConditionalKeys.PersonTags].filter(v => tags.has(v.id));
    }

    initializeForm(form: FormGroup, value: PersonMatcher, options?: MatcherOptions) {
        if (options?.people) this.filterPeople(options.people);
        else this.formValues = this.allValues;

        const includes = options?.includes ?? DEFAULT_KEYS;
        if (!form.get('advancedCondition')) {
            const advanced = new FormGroup({
                conditions: new FormArray((value?.advancedCondition?.conditions ?? []).map(v => this.getFormGroup(v))),
                type: new FormControl(value?.advancedCondition?.type ?? 'and'),
                enabled: new FormControl(value?.advancedCondition?.enabled ?? false),
            });
            form.addControl('advancedCondition', advanced);
        }
        if (options?.legacy) {
            Object.keys(StandardConditions).forEach(key => {
                if (!includes.includes(StandardConditions[key].key)) return;

                const formValue = form.get(key)?.value ?? value?.[key] ?? [];
                if (!form.get(key)) form.addControl(key, new FormControl(formValue));
            });
        } else if (!form.get('standardConditions')) {
            const standard = new FormArray((value?.standardConditions ?? []).map(v => this.getFormGroup(v)));
            form.addControl('standardConditions', standard);
        }
    }

    private getFormGroup(condition: PersonCondition) {
        if ((condition as SimplePersonCondition).key) {
            const simple = condition as SimplePersonCondition;
            return new FormGroup({
                key: new FormControl(simple.key),
                negate: new FormControl(simple.negate),
                value: new FormControl(simple.value),
                self: new FormControl(simple.self ?? false),
            });
        } else {
            const complex = condition as ComplexPersonCondition;
            return new FormGroup({
                conditions: new FormArray((complex?.conditions ?? []).map(v => this.getFormGroup(v))),
                type: new FormControl(complex?.type ?? 'and'),
            });
        }
    }

    setAdvanced(advanced: boolean, standard: FormArray, includes = DEFAULT_KEYS, includeSelf = false) {
        const key: DropdownItem[] = [];
        const negate: NegateOptions = {};
        Object.values(ConditionalKeys).forEach(conditionalKey => {
            if (!includes.includes(conditionalKey)) return;

            const inUseIncludes = !advanced && standard.controls.find(c => c.get('key').value === conditionalKey
                && c.get('negate')?.value !== true && c.get('self')?.value !== true) as FormGroup;
            const inUseExcludes = !advanced && standard.controls.find(c => c.get('key').value === conditionalKey
                && c.get('negate')?.value === true && c.get('self')?.value !== true) as FormGroup;
            const inUseSelf = !advanced && standard.controls.find(c => c.get('key').value === conditionalKey
                && c.get('self')?.value === true) as FormGroup;
            const label = {
                [ConditionalKeys.People]: 'rField.people.people',
                [ConditionalKeys.PersonCompany]: 'rField.people.withCompany',
                [ConditionalKeys.PersonCraft]: 'rField.people.withCraft',
                [ConditionalKeys.PersonTags]: 'rField.people.taggedAs',
                [ConditionalKeys.PersonSites]: 'rField.people.withSite',
            }[conditionalKey];
            key.push({
                key: conditionalKey,
                label,
                disabled: !!inUseIncludes && !!inUseExcludes && (!includeSelf || !!inUseSelf),
            });
            negate[conditionalKey] = [
                { key: 'include', label: 'rField.filters.include', disabled: !!inUseIncludes, control: inUseIncludes },
                { key: 'exclude', label: 'rField.filters.exclude', disabled: !!inUseExcludes, control: inUseExcludes },
            ];
            if (includeSelf) {
                negate[conditionalKey].push({ key: 'self', label: 'rField.filters.self', disabled: !!inUseSelf, control: inUseSelf });
            }
        });
        if (!isEqual(this.key$.value, key) || !isEqual(this.negate$.value, negate)) {
            this.key$.next(key);
            this.negate$.next(negate);
        }
    }

    removeInvalidConditions(value: PersonMatcher, excludeKeys?: ConditionalKeys[]) {
        const keep = (condition: PersonCondition) => {
            if ((condition as SimplePersonCondition).key) {
                return !excludeKeys?.includes((condition as SimplePersonCondition).key)
                    && (!!(condition as SimplePersonCondition).value?.length
                        || (condition as SimplePersonCondition).self);
            }
            const complex = condition as ComplexPersonCondition;
            complex.conditions = complex.conditions.filter(condition => keep(condition));
            return !!complex.conditions.length;
        };
        value.standardConditions = value.standardConditions?.filter(condition => keep(condition));
        keep(value.advancedCondition);
    }
}